import * as React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import Layout from "../components/Layout";
import LegalHero from "../components/Hero/LegalHero";
import Markdown from "../components/Markdown";
import SEO from "../components/SEO";


const LegalTemplate = ({
  data
}) => {
  const {
    mdx: {
      body,
      frontmatter: {
        title,
        lastUpdated,
      }
    }
  } = data;

  return (
    <Layout>
      <SEO title={ title } />

      <LegalHero
        pageTitle={ title }
        lastUpdated={ lastUpdated }
      />

      <Markdown
        MDXRendererComponent={
          <MDXRenderer>{ body }</MDXRenderer>
        }
      />
    </Layout>
  )
}


export const pageQuery = graphql`
  query($id: String) {
    mdx(id: {
      eq: $id
    }) {
      id
      body
      frontmatter {
        title
        lastUpdated
      }
    }
  }
`;


export default LegalTemplate;
