import styled from "styled-components";

import {
  CONTAINERS,
  PADDINGS,
  SCREENS
} from "../../../theme/style-constants";


export const StyledSection = styled.section`
  width: 100%;
`;

export const Container = styled.div`
  max-width: ${ CONTAINERS.post };
  width: 90%;
  margin: 0 auto;
  padding: ${ PADDINGS.container.section.mobile };

  @media (min-width: ${ SCREENS.laptop }) {
    padding: ${ PADDINGS.container.section.laptop };
  }
`;

export const StyledHeading = styled.h1`
  margin: 0 0 1rem;
`;

export const StyledDate = styled.p``;
