import * as React from "react";

import {
  Container,
  StyledDate,
  StyledHeading,
  StyledSection
} from "./style";


const LegalHero = ({
  pageTitle,
  lastUpdated
}) => {
  return (
    <StyledSection>
      <Container>
        <StyledHeading>
          { pageTitle }
        </StyledHeading>
        <StyledDate>Last Updated: { lastUpdated }</StyledDate>
      </Container>
    </StyledSection>
  )
}


export default LegalHero;
